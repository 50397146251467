.task-card {
    box-sizing: border-box;
    flex-shrink: 0;
    position: relative;

    width: 100%;

    display: flex;
    gap: 20px;

    padding: 16px 20px;

    background: var(--tgui--bg_color);
    border-radius: 16px;
    backdrop-filter: blur(10px);
}
.task-card__text {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 2px;
}
