.user-list-item {
    position: relative;

    display: flex;
    align-items: center;
    gap: 8px;

    padding: 16px;

    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.user-list-item__avatar {
    flex-shrink: 0;
}

.user-list-item__content {
    display: flex;
    flex-direction: column;

    flex-grow: 1;
}

.user-list-item__append {
    flex-shrink: 0;

    display: flex;
    align-items: center;
}
