.leaderboard-search-wrapper {
    box-sizing: border-box;

    display: flex;
    width: 100%;
    padding-right: 16px;
}

.leaderboard-search-wrapper > div {
    padding: 0 !important;
    background: transparent !important;
    flex-grow: 1;
}

.leaderboard-search-wrapper > div > label {
    padding: 0 !important;
    background: transparent !important;
}

.leaderboard-search-wrapper > div > label > div > button > svg > g {
    fill: currentColor;
    opacity: 1;
}

.leaderboard-search-wrapper > div > label > input {
    padding: 8px 12px !important;
    min-height: 24px !important;
}
