.create-textarea-wrapper textarea {
    resize: none !important;
    padding: 0 !important;
    min-height: 24px !important;
}

.create-textarea-wrapper > div {
    padding: 0 16px !important;
    background: transparent !important;
}

.create-textarea-wrapper > div > label {
    border-radius: 0 !important;
    border: none !important;
    box-shadow: none !important;
    background: transparent !important;
}

.uploaded-images {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 0 16px 12px 16px;
    flex-wrap: wrap;
    position: relative;
}

.upload-image-wrapper {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 12px;

    min-width: 106px;
    min-height: 106px;

    /*width: 106px;*/
    /*height: 106px;*/

    max-width: 100%;
    max-height: 100%;
}

.upload-image-wrapper > .upload-image {
    width: 100%;
    height: 100%;

    object-fit: cover;
    border-radius: inherit;
}

.upload-image-wrapper > .upload-image-overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: inherit;
}

.upload-image-wrapper .upload-image__close {
    position: absolute;
    top: 6px;
    right: 6px;

    padding: 0;
    opacity: 0.8;
    border: 2px solid rgba(255, 255, 255);
    background: rgba(0, 0, 0, 0.4);
    box-shadow: none;

    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-image-wrapper .upload-image__close:hover {
    opacity: 1;
}

.upload-image-wrapper .upload-image__close:active {
    background: rgba(0, 0, 0, 0.6);
}

.upload-image-wrapper .upload-image__close > span {
    display: none;
}

.upload-image-wrapper .upload-image__close > svg > g {
    fill: #fff;
    opacity: 1;
}
