/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  --background-color: black;
  --secondary-background-color: rgb(20, 20, 20);
  --secondary-color: rgb(130, 130, 130);
  --separator-color: rgba(80, 80, 80, 0.5);
  --primary-color: white;

  background-color: var(--background-color);
  color: var(--primary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

html,
body,
#root {
  overscroll-behavior: none;
  width: 100%;
  height: 100%;
  margin: 0;
}

button {
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
} */

:root {
  --safe-area-inset-bottom: calc(100vh - var(--tg-viewport-stable-height, 100vh));
}

.container {
  padding: 16px;

  max-height: calc(100vh - var(--tab-bar-height));
  overflow-y: auto;
  overflow-x: hidden;

  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: var(--tg-theme-secondary-bg-color);

  overflow-x: hidden;
}

/* fix for rect shape hover in telegram ui buttons */
button[class^="tgui"]::after {
  border-radius: inherit;
}

.spinner-xl {
  display: flex;
}

.spinner-xl > svg {
  width: 96px;
  height: 96px;
}

.skeleton::before, .skeleton::after {
  border-radius: inherit;
}
