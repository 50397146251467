.leaderboard-top-item {
    display: flex;
    flex-direction: column;
    gap: 2px;

    border-radius: 12px;
    overflow: hidden;
}

.leaderboard-top-item__top {
    display: flex;
    align-items: center;
    gap: 8px;

    padding: 12px;
}

.leaderboard-top-item__top {
    background: #FF8A0021;
}

.leaderboard-top-item__avatar {
    flex-shrink: 0;
}

.leaderboard-top-item__text {
    display: flex;
    flex-direction: column;

    flex-grow: 1;
}
