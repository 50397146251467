.leaderboard-item::after {
    content: '';
    position: absolute;

    bottom: 0;
    left: 0;
    right: calc(100% - var(--progress, 0%));

    height: 1px;
    background: #31D158;
}
